<template>
  <div>
      <router-view/>
      <transition name="fade">
        <router-view name="modal2"></router-view>
      </transition>
  </div>
</template>

<script>
export default {
  mounted () {
    let loader = this.$loading.show();
    setTimeout(() => loader.hide(), Math.random() * 200 + 200)
  },
}
</script>



